;var GoogleMaps = (function ($) {

    var GoogleMaps = function ($target) {
        this.$target = $target;
        this.coordinates = $target.attr('data-coordinates').split(',');

        this.init();
    }

    GoogleMaps.prototype.init = function() {
        var isSidebarComponent = this.$target.closest('.is--aside').length ? true : false;
        var position = new google.maps.LatLng(this.coordinates[0], this.coordinates[1]);

        var map = new google.maps.Map(this.$target.get(0), { 
            zoom: isSidebarComponent ? 13 : 16, 
            center: position,
            disableDefaultUI: isSidebarComponent ? true : false,
            zoomControl: true
        });

        var marker = new google.maps.Marker({ position: position, map: map });
    }

    $(function() {
        var mapsScript = $('.google-maps-script');
        var maps = $('[data-google-maps]');

        if(!mapsScript.length && maps.length) {
            var key = maps.eq(0).attr('data-key');
            var script = '<script class="google-maps-script" src="https://maps.googleapis.com/maps/api/js?key=' + key + '&callback=initMaps"></script>';
            $('body').append(script);

            maps.removeAttr('data-key');
        }
    });

    return GoogleMaps;

})(jQuery);

function initMaps() {
    $('.google-maps-script').remove();

    $('[data-google-maps]').each(function() {
        new GoogleMaps($(this));
    });
}
