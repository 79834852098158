/**
 * InViewPort module
 * - Returns whether an element is in viewport
 * PAram middle return when object middle of viewport
 */
;var inviewport = (function($, window) { // eslint-disable-line no-extra-semi
    /**
     * Return whether an element is in viewport
     * @param element
     * @returns {string|*}
     */
    return function (element, middle) {
        /**
         * Get element and window bounds
         * @type {ClientRect}
         */
        var elementTop = $(element).offset().top,
            elementBottom = $(element).offset().top + $(element).outerHeight(),
            screenBottom = $(window).scrollTop() + ($(window).innerHeight() * 2),
            screenTop = $(window).scrollTop() - $(window).innerHeight();
            
        /**
         * Return whether the bounds are within the viewport
         */
        return (
            (screenBottom > elementTop) && (screenTop < elementBottom)
        );
    };
})(jQuery, window);

/**
 * Export for unit tests
 */
if ("object" == typeof module && "object" == typeof module.exports) {
    module.exports = inviewport;
}
