;var pageTransition = (function ($) {
    'use strict';

    $(function () {

        var canNavigate = false;
      $('a').not('[target="_blank"], [href*="mailto:"], [href*="tel:"], [href*="#"]').not('.cc-btn').each(function() {
            $(this).click(function(event) {
                $('body').css({'opacity': '0'});

                var href = $(this).attr('href');
                setTimeout(function() { window.location = href }, 150);
                event.preventDefault();
            });
        });
        $('body').css({'opacity': '1'});
        $(window).on('hashchange', function(e){
            $('body').css({'opacity': '1'});
        });

    });

})(jQuery);
